<template>
  <div class="content content_in">
    <h1 class="title-h2">{{ $t("dashboard.table.title") }}</h1>
    <div class="c-table mb-3">
      <vue-good-table
        styleClass="table vgt-table sortEnable tablesorter tablesorter-bootstrap"
        :columns="columns"
        :rows="rows"
        :line-numbers="true"
        :sort-options="{
          enabled: true,
          multipleColumns: true,
        }"
      >
        <template slot="table-column" slot-scope="props">
          <span class="cursor-pointer">
            {{ formatLabel(props.column.label) }}
          </span>
        </template>
        <template slot="table-row" slot-scope="props">
          <div class="country" v-if="props.column.field === checkCountry">
            <gb-flag width="24px" height="16px" :code="props.row.iso" />
            <span class="flag-text">
              {{
                $i18n.locale === "ru"
                  ? props.row.country_name_ru
                  : props.row.country_name_en
              }}
            </span>
          </div>
          <div class="country" v-if="props.column.field === 'avg_price_mob'">
            <b>$ {{ props.row.avg_price_mob }}</b>
          </div>
          <div class="country" v-if="props.column.field === 'avg_price_pc'">
            <b>$ {{ props.row.avg_price_pc }}</b>
          </div>
          <div
            class="country"
            v-if="props.column.field === 'avg_profit_per_thousand_mob'"
          >
            <b>$ {{ props.row.avg_profit_per_thousand_mob }}</b>
          </div>
          <div
            class="country"
            v-if="props.column.field === 'avg_profit_per_thousand_pc'"
          >
            <b>$ {{ props.row.avg_profit_per_thousand_pc }}</b>
          </div>
        </template>
        <div slot="emptystate">
          {{ $t("any_results") }}
        </div>
      </vue-good-table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.c-table ::v-deep th.sorting-desc {
  button:after {
    display: none;
  }
}

.c-table ::v-deep th.sorting-asc {
  button:before {
    display: none;
  }
}

.c-table ::v-deep th.sortable button {
  margin-left: -2px;
  &:after,
  &:before {
    left: 0;
  }
}
</style>

<script>
import "@/assets/css/dashboard.scss";
import DashboardService from "@/api/dashboardService";
import moment from "moment";

export default {
  name: "Top",
  beforeMount() {
    document.title = `Revopush | ${this.$t("top_countries")}`;
  },
  beforeUpdate() {
    document.title = `Revopush | ${this.$t("top_countries")}`;
  },
  data() {
    return {
      columns: [
        {
          label: "dashboard.table.country",
          field: "country_name",
        },
        {
          label: "dashboard.table.average_price_mob",
          field: "avg_price_mob",
          type: "price",
          sortFn: this.sortFn,
        },
        {
          label: "dashboard.table.average_price_pc",
          field: "avg_price_pc",
          type: "price",
          sortFn: this.sortFn,
        },
        {
          label: "dashboard.table.average_income_mob",
          field: "avg_profit_per_thousand_mob",
          type: "price",
          sortFn: this.sortFn,
        },
        {
          label: "dashboard.table.average_income_pc",
          field: "avg_profit_per_thousand_pc",
          type: "price",
          sortFn: this.sortFn,
        },
      ],
      rows: [],
    };
  },
  mounted() {
    this.getAllCountries();
    this.columns[0].field = this.checkCountry;
  },
  watch: {
    "$i18n.locale": function () {
      this.columns[0].field = this.checkCountry;
    },
  },
  computed: {
    checkCountry() {
      return this.$i18n.locale === "ru" ? "country_name_ru" : "country_name_en";
    },
  },

  methods: {
    getAllCountries() {
      DashboardService.getAllCounties().then((res) => {
        if (res.status === 200) {
          this.rows = res.data;
        }
      });
    },
    formatLabel(el) {
      return this.$t(el);
    },
    moment: (e) => {
      return moment(e);
    },
    sortFn(x, y) {
      return Number(x) - Number(y);
    },
  },
};
</script>



